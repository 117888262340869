import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import InfoScreen from '../../components/InfoScreen/InfoScreen';
import webAppStore from '../../stores/WebAppStore';
import styles from './AmlScreen.module.scss';
import WalletInput from '../../components/WalletInput/WalletInput';
import AmlStore from '../../stores/AmlStore';
import SuccessRequest from '../../components/SuccessRequest/SuccessRequest';

const InnerAml = () => {

    return (
        <div className={styles.content}>
            <div>
                Введите адрес кошелька
                для проверки
            </div>
            <div className={styles.walletBlock}>
                <WalletInput
                    value={AmlStore.Wallet}
                    onChange={AmlStore.setWallet}
                    onValidationChange={AmlStore.setIsValid}
                    inputType='crypto'
                />
            </div>
            <div className={styles.remainChecks}>
                Осталось проверок: {AmlStore.Quota}
            </div>
        </div>
    )
}

const AmlScreen = observer(() => {
    const navigate = useNavigate();
    useEffect(() => {
        webAppStore.updateBackButtonVisibility();
    }, []);

    const onSubmit = async () => {
        await AmlStore.checkWallet();
    }
    const InfoClick = () => {
        navigate('/amlInfo');
    }
    if (AmlStore.SuccessRequestId) {
        return (
            <SuccessRequest store={AmlStore} />
        );
    }
    return (
        <InfoScreen
            header="AML Проверка"
            showInfo={true}
            submitAsync={onSubmit}
            onInfoClick={InfoClick}
            description={<InnerAml />}
            buttonLabel="Проверить кошелек"
            isFormValid={AmlStore.IsValid} />
    );
});

export default AmlScreen;

