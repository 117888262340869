import { makeAutoObservable, toJS } from 'mobx';
import $api from '../http';

class RequestStore {
    requests = []; // Общий список заявок

    constructor() {
        makeAutoObservable(this);

        // Пример тестовых данных
        this.requests = [
            { id: 1, type: 'Обмен', amount: '100 USDT', status: 0, date: "10.01.2025" },
            { id: 2, type: 'ВЭД', amount: '150,000 USD', status: 1, date: "12.01.2025" },
            { id: 3, type: 'Инвойс', amount: '2,500 EUR', status: 2, date: "31.10.2024" },
        ];
    }

    // Получение заявок с сервера
    async fetchRequests() {
        try {
            const conv = await this.fetchConvRequests();
            const wed = await this.fetchWedRequests();
            const invoice = await this.fetchInvoiceRequests();

            this.requests = [...conv, ...wed, ...invoice];
        } catch (error) {
            console.error('Ошибка при загрузке заявок:', error);
        }
    }

    async fetchConvRequests() {
        try {
            const response = await $api.get("/tr/conv");
            console.log(response)
            if (response.status === 200) {
                return response.data.map(request => ({
                    id: request.id,
                    type: 'Обмен', // Устанавливаем фиксированное значение "Обмен"
                    amount: `${request.amount_from} ${request.currency_from}`, // Форматируем amount
                    status: request.status,
                    date: request.created_at.split('T')[0], // Обрезаем дату до дня
                }));
            }
        } catch (error) {
            console.error("Failed to fetch conv requests:", error);
            return [
                { id: 3, type: 'Инвойс', amount: '2,500 EUR', status: 2, date: "31.10.2024" },
                { id: 4, type: 'Обмен', amount: '200 USDT', status: 0, date: "10.01.2025" },
            ];
        }

    }

    async fetchWedRequests() {
        return [
            { id: 2, type: 'ВЭД', amount: '150,000 USD', status: 1, date: "12.01.2025" },
            { id: 5, type: 'ВЭД', amount: '50,000 USD', status: 1, date: "12.01.2025" },
        ];
    }

    async fetchInvoiceRequests() {
        return [
            { id: 1, type: 'Обмен', amount: '100 USDT', status: 0, date: "10.01.2025" },
            { id: 6, type: 'Инвойс', amount: '3,000 EUR', status: 2, date: "31.10.2024" },
        ];
    }

    // Геттеры для фильтрации заявок
    get activeRequests() {
        return this.requests.filter((request) => request.status === 0 || request.status === 1);
    }

    get completedRequests() {
        return this.requests.filter((request) => request.status === 2);
    }
}

export default new RequestStore();
