import React from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { ReactComponent as InfoIcon } from '../../images/Info.svg';
import styles from './InfoScreen.module.scss';

const InfoScreen = observer(({ header, description, backLink, isFormValid = true, buttonLabel = "Назад", showInfo = false, onInfoClick = null, submitAsync = null }) => {
    const navigate = useNavigate();

    const onSubmit = async (e) => {
        e.preventDefault();
        if (submitAsync === null) {
            navigate(backLink);
        }
        else {
            await submitAsync();
        }
    };

    return (
        <div className={styles.infoPage}>
            <div className={styles.headerRow}>
                <div className={styles.headerTitle}>
                    {header}
                </div>
                {showInfo && (
                    <div className={styles.infoIconBlock} onClick={onInfoClick}>
                        <InfoIcon />
                    </div>)
                }

            </div>
            <div className={styles.headerDescription}>
                {description}
            </div>
            <div className={styles.buttonsBlock}>
                <Button type="btnThree" onClick={onSubmit} label={buttonLabel} disabled={!isFormValid} />

            </div>
        </div>
    );
});

export default InfoScreen;
