import React, { useState, useCallback } from 'react';
import './WalletInput.scss';

const WalletInput = ({
  value,
  onChange,
  onValidationChange,
  inputType = 'crypto',
}) => {
  const [isShaking, setIsShaking] = useState(false);
  const [error, setError] = useState('');
  const [isDirty, setIsDirty] = useState(false);

  const validateCryptoAddress = (address) => {
    if (!address) return false;

    // Базовые паттерны для разных сетей
    const patterns = {
      // Bitcoin-like (BTC, LTC)
      btcLike: /^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$/,
      // Bitcoin SegWit
      segWit: /^bc1[ac-hj-np-z02-9]{11,71}$/,
      // Ethereum-like (ETH, BNB Smart Chain)
      ethLike: /^0x[a-fA-F0-9]{40}$/,
      // TRON (TRC20)
      tron: /^T[a-zA-Z0-9]{33}$/,
      // TON
      ton: /^[0-9a-z-_]{48}$/i,
      // General base58
      base58: /^[1-9A-HJ-NP-Za-km-z]{32,44}$/,
    };

    // Проверяем адрес по всем паттернам
    return Object.values(patterns).some(pattern => pattern.test(address));
  };

  const validateCardNumber = (number) => {
    const cleanNumber = number.replace(/\D/g, '');
    return {
      isValid: cleanNumber.length === 16 && /^\d+$/.test(cleanNumber),
      cleanNumber
    };
  };

  const formatCardNumber = (value) => {
    const cleanNumber = value.replace(/\D/g, '');
    const groups = cleanNumber.match(/.{1,4}/g);
    return groups ? groups.join(' ') : cleanNumber;
  };

  const validateWalletAddress = (address) => {
    if (!address) {
      return 'Поле обязательно для заполнения';
    }

    if (inputType === 'crypto') {
      if (!validateCryptoAddress(address)) {
        return 'Неверный формат адреса кошелька';
      }
    } else {
      const { isValid } = validateCardNumber(address);
      if (!isValid) {
        return 'Неверный формат номера карты';
      }
    }
    return '';
  };

  const handleInputChange = useCallback((e) => {
    let newValue = e.target.value;

    if (inputType === 'card') {
      // Оставляем только цифры и пробелы
      newValue = newValue.replace(/[^\d\s]/g, '');
      // Ограничиваем длину до 16 цифр (19 символов с пробелами)
      const cleanNumber = newValue.replace(/\s/g, '');
      if (cleanNumber.length <= 16) {
        newValue = formatCardNumber(newValue);
      } else {
        return; // Игнорируем ввод, если превышена максимальная длина
      }
    }

    setIsDirty(true);
    onChange(newValue);

    const validationError = validateWalletAddress(newValue);
    setError(validationError);
    onValidationChange?.(validationError === '');

    if (newValue && validationError && validationError !== 'Поле обязательно для заполнения') {
      setIsShaking(true);
      setTimeout(() => setIsShaking(false), 500);
    }
  }, [onChange, onValidationChange, inputType]);

  const handleBlur = useCallback(() => {
    if (isDirty) {
      const validationError = validateWalletAddress(value);
      setError(validationError);
      onValidationChange?.(validationError === '');
    }
  }, [value, isDirty, onValidationChange]);

  const handlePaste = useCallback((e) => {
    e.preventDefault();
    let pastedText = e.clipboardData.getData('text').trim();

    if (inputType === 'card') {
      // Для карт оставляем только цифры
      const cleanNumber = pastedText.replace(/\D/g, '');
      if (cleanNumber.length <= 16) {
        pastedText = formatCardNumber(cleanNumber);
      } else {
        return;
      }
    } else {
      // Для крипто убираем пробелы в начале и конце
      pastedText = pastedText.trim();
    }

    onChange(pastedText);

    const validationError = validateWalletAddress(pastedText);
    setError(validationError);
    onValidationChange?.(validationError === '');

    if (validationError && validationError !== 'Поле обязательно для заполнения') {
      setIsShaking(true);
      setTimeout(() => setIsShaking(false), 500);
    }
  }, [onChange, onValidationChange, inputType]);

  return (
    <div className="wallet-input">
      <div
        className={`custom-input-container ${isShaking ? 'shake' : ''} ${error && isDirty ? 'error' : ''}`}
      >
        <div className="custom-input-wrapper">
          <div className="custom-input-field-wrapper">
            <input
              className="custom-input-field"
              type="text"
              value={value}
              onChange={handleInputChange}
              onBlur={handleBlur}
              onPaste={handlePaste}
              placeholder={inputType === 'crypto' ? 'Адрес кошелька*' : 'Со счёта*'}
              autoComplete="off"
              maxLength={inputType === 'card' ? 19 : undefined}
            />
          </div>
        </div>
      </div>
      {error && isDirty && (
        <div className="custom-input-error">
          {error}
        </div>
      )}
    </div>
  );
};

export default WalletInput;