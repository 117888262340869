import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import secured from '../../images/png/Secured.png';
import Button from '../../components/Button/Button';
import webAppStore from '../../stores/WebAppStore';
import styles from './SuccessRequest.module.scss';

const SuccessRequest = observer(({ store }) => {
    const navigate = useNavigate();

    const managerUsername = "proself";

    const handleContactManager = () => {
        window.open(`https://t.me/${managerUsername}`, '_blank');
    };
    useEffect(() => {
        return () => {
            store.successRequestId = null;
        };
    }, [store]);

    return (
        <div className={styles.requestPage}>
            <div className={styles.imageBlock}>
                <img src={secured} alt="Shield" />
            </div>
            <h1 className={styles.successTitle}>Заявка создана</h1>
            <div className={`${styles.headerDescription} ${styles.selector} ${styles.successTitle}`}>
                Ваша заявка передана менеджеру
            </div>
            <div className={styles.request}>
                <div className={styles.requestBlock}>
                    <div className={styles.requestBlockLabel}>Номер заявки</div>
                    <div className={styles.requestBlockId}>{store.successRequestId}</div>
                </div>
                <div className={styles.requestBtnPosition}>
                    <Button type="btnThree" label="Связаться с менеджером" onClick={handleContactManager} />
                </div>
            </div>
        </div>
    );
});

export default SuccessRequest;
