import React, { useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import VedStore from '../../stores/VedStore';
import { ReactComponent as FileIcon } from '../../images/FileAttach.svg';
import { XCircle, FileText, FileImage, ArchiveX } from 'lucide-react';
import TextFieldGenerator from '../TextFieldsGenerator/TextFieldsGenerator';
import styles from './VedPaymentDataBlock.module.scss'

const VedPaymentDataBlock = observer(({ fields, style }) => {
    const [attachedFiles, setAttachedFiles] = useState([]);
    const fileInputRef = useRef(null);

    const handleFileAttachClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const handleFileAttach = (e) => {
        if (!e.target.files || e.target.files.length === 0) return;

        const file = e.target.files[0];
        if (file) {
            const updatedFiles = [...attachedFiles, file];
            setAttachedFiles(updatedFiles);
            VedStore.setVedRequestFiles(updatedFiles);
        }

        e.target.value = "";
    };

    const handleFileDelete = (index) => {
        const updatedFiles = [...attachedFiles];
        updatedFiles.splice(index, 1);
        setAttachedFiles(updatedFiles);
        VedStore.setVedRequestFiles(updatedFiles);
    };
    const getFileIcon = (file) => {
        const fileExtension = file.name.split('.').pop().toLowerCase();
        switch (fileExtension) {
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
                return <FileImage className={styles.fileIcon} />;
            case 'zip':
            case 'rar':
            case 'tar':
            case 'gz':
                return <ArchiveX className={styles.fileIcon} />;
            default:
                return <FileText className={styles.fileIcon} />;
        }
    };
    return (
        <div className={style}>
            <TextFieldGenerator fields={fields} style={style} store={VedStore} />
            <div className={styles.description}>
                Реквизиты компании  в РФ
            </div>
            {attachedFiles.length > 0 && (
                <div className={styles.attachedFiles}>
                    {attachedFiles.map((file, index) => (
                        <div key={index} className={styles.attachedFile}>
                            {getFileIcon(file)}
                            <div className={styles.fileName}>{file.name}</div>
                            <div className={styles.fileDelete} onClick={() => handleFileDelete(index)}>
                                <XCircle size={16} />
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <div className={styles.fileAttachBlock} onClick={handleFileAttachClick}>
                <FileIcon />
                <div className={styles.fileAttachText}>
                    Прикрепить файл
                </div>
                <input
                    id="file-input"
                    ref={fileInputRef}
                    type="file"
                    className="sr-only"
                    onChange={handleFileAttach}
                />
            </div>
        </div>
    );
});

export default VedPaymentDataBlock;