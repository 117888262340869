import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import SuccessRequest from '../../../components/SuccessRequest/SuccessRequest';
import webAppStore from '../../../stores/WebAppStore';
import { ReactComponent as InfoIcon } from '../../../images/Info.svg';
import styles from './InvoiceUsdtScreen.module.scss';
import InvoiceStore from '../../../stores/InvoiceStore';
import InvoicePaymentDataBlock from '../../../components/InvoicePaymentDataBlock/InvoicePaymentDataBlock';

const InvoiceUsdtScreen = observer(() => {
    const navigate = useNavigate();

    useEffect(() => {
        webAppStore.updateBackButtonVisibility();
    }, []);

    const onInfoClick = (e) => {
        e.preventDefault();
        navigate('/invoiceInfo');
    }
    const fields = [
        { name: 'amount', placeholder: 'Сумма' },
        { name: 'beneficiaryName', placeholder: 'Beneficiary name' },
        { name: 'beneficiaryAddress', placeholder: 'Beneficiary address' },
        { name: 'bank', placeholder: 'Bank' },
        { name: 'bankAddress', placeholder: 'Bank address' },
        { name: 'account', placeholder: 'Account' },
        { name: 'swift', placeholder: 'SWIFT' },
        { name: 'currency', placeholder: 'Currency' },
        { name: 'remark', placeholder: 'Remark' }
    ];
    const onSubmit = async (e) => {
        e.preventDefault();
        await InvoiceStore.submitUsdtInvoice();
        console.log(InvoiceStore.SuccessRequestId)
    };
    if (InvoiceStore.SuccessRequestId) {

        return <SuccessRequest store={InvoiceStore} />;
    }
    return (
        <div className={styles.invoicePayment}>
            <div className={styles.invoicePaymentHeader}>
                <div className={styles.invoicePaymentTitle}>
                    <span>
                        Оплатить<br />
                        инвойс
                        за USDT
                    </span>
                </div>
                <div className={styles.infoIconBlock} onClick={onInfoClick}>
                    <InfoIcon />
                </div>
            </div>
            <div className={styles.invoicePaymentDataBlocks}>
                <InvoicePaymentDataBlock fields={fields} style={styles.invoicePaymentDataBlock} />
            </div>
            <div className={styles.buttonsBlock}>
                <Button type="btnThree" onClick={onSubmit} disabled={!InvoiceStore.isFormValid} label="Создать заявку" />
            </div>
        </div>
    );
});

export default InvoiceUsdtScreen;
