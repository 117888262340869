import React from 'react';
import InfoScreen from '../../components/InfoScreen/InfoScreen';

const ExchangeInfoScreen = () => {
    return (
        <InfoScreen
            header="Условия обмена криптовалюты"
            description={`1. Общие положения

Наш обменный сервис предоставляет услуги по обмену криптовалюты USDT на наличные (рубли, доллары) в офисах, расположенных в различных странах и городах.
Оформление заявок осуществляется через MiniApps в Telegram.

2. Процесс обмена

Подача заявки: Клиент заполняет заявку в MiniApps, указывая сумму, валюту обмена и предпочитаемый город для проведения операции.
Подтверждение заявки: После отправки заявки менеджер связывается с клиентом через указанный контакт для уточнения деталей и согласования курса.
Проведение обмена: Обмен производится в согласованном офисе в оговоренное время. Клиент переводит оговоренную сумму USDT на предоставленный кошелек, после чего получает эквивалентную сумму в наличной валюте.

3. Курс и комиссии

Фиксация курса: Курс обмена фиксируется в момент подтверждения заявки и действует в течение оговоренного времени.
Комиссии: Комиссия за обмен зависит от суммы и текущих рыночных условий. Точные размеры комиссий сообщаются менеджером при подтверждении заявки.

4. Требования к клиенту

Идентификация: В зависимости от суммы и законодательства страны проведения обмена, может потребоваться предоставление удостоверения личности.
Возрастные ограничения: Услуги предоставляются только лицам, достигшим 18 лет.

5. Безопасность и конфиденциальность

Конфиденциальность: Мы гарантируем конфиденциальность всех операций и не разглашаем информацию о клиентах третьим лицам, за исключением случаев, предусмотренных законодательством.
Безопасность сделок: Все операции проводятся в безопасных офисах с соблюдением необходимых мер предосторожности.

6. Ограничения и ответственность

Ограничения по суммам: Минимальная и максимальная суммы обмена могут варьироваться в зависимости от города и наличия средств. Точные ограничения уточняйте у менеджера при оформлении заявки.
Ответственность: Сервис не несет ответственности за задержки или ошибки, вызванные неверно предоставленной информацией клиентом или техническими сбоями вне зоны нашего контроля.

7. Дополнительные условия

Изменение условий: Сервис оставляет за собой право изменять условия обмена без предварительного уведомления. Актуальные условия всегда доступны при оформлении заявки.
Отмена заявки: Клиент имеет право отменить заявку до момента подтверждения перевода USDT. После подтверждения отмена невозможна.`}
            backLink="/change" />
    );
}

export default ExchangeInfoScreen;
