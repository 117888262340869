import React from 'react';
import { observer } from 'mobx-react-lite';
import TextInput from '../../components/TextInput/TextInput';



const TextFieldGenerator = observer(({ fields, style, store }) => {
    return (
        <div className={style}>
            {fields.map((field) => (
                <TextInput
                    key={field.name}
                    value={store.data[field.name]}
                    onChange={(value) => store.setField(field.name, value)}
                    placeholder={field.placeholder}
                />
            ))}
        </div>
    );
});

export default TextFieldGenerator;