// stores/CreateExchangeStore.jsx
import { makeAutoObservable, toJS } from 'mobx';
import $api from '../http';
import { ReactComponent as RubleIcon } from '../images/ruble.svg';
import { ReactComponent as UsdtIcon } from '../images/usdt.svg';
import { ReactComponent as UsdIcon } from '../images/usd.svg';
import { ReactComponent as AedIcon } from '../images/aed.svg';

class ExchangeStore {
    currencyPairs = [];
    baseAvailableCurrencies = [
        {
            type: "fiat",
            currencies: [
                { name: "usd", icon: <UsdIcon /> },
                { name: "rub", icon: <RubleIcon /> },
                { name: "aed", icon: <AedIcon /> },
            ]
        },
        {
            type: "crypto",
            currencies: [
                { name: "usdt", icon: <UsdtIcon /> },
            ]
        }
    ];
    availableCurrencies = [...this.baseAvailableCurrencies];
    exchangeRates = { "usdt": {} };
    currencyTo = this.availableCurrencies[0].currencies[1];
    currencyFrom = this.availableCurrencies[1].currencies[0];
    amountTo = '';
    amountFrom = '';
    wallet = '';
    requestSuccess = false;
    successMessage = '';
    errorMessage = '';
    successRequestId = null;

    constructor() {
        makeAutoObservable(this);
    }

    async fetchAvailableCurrencies(currency_pairs) {
        try {
            //const response = await $api.get('/user/me');
            console.log(currency_pairs)
            const currencyPairs = currency_pairs || [];
            if (currencyPairs && currencyPairs.length > 0) {
                this.processServerData(currencyPairs);
            } else {
                throw new Error('Список доступных валют пуст');
            }
        } catch (error) {
            console.error('Ошибка при загрузке доступных валют:', error);
            this.errorMessage = 'Не удалось загрузить доступные валюты. Попробуйте позже.';
        }
    }

    processServerData(currencyPairs) {
        const normalizedPairs = currencyPairs.map(pair => this.normalizeCurrencyPair(pair));
        const availableFiat = new Set();
        const availableCrypto = new Set(['USDT']);
        const newExchangeRates = {
            "usdt": {}
        };

        normalizedPairs.forEach(pair => {
            const { code_one, code_two, total_rate_from, total_rate_to } = pair;
            if (code_one === 'USDT') {
                availableFiat.add(code_two.toLowerCase());
                newExchangeRates.usdt[code_two.toLowerCase()] = total_rate_to;
            } else if (code_two === 'USDT') {
                availableFiat.add(code_one.toLowerCase());
                newExchangeRates.usdt[code_one.toLowerCase()] = 1 / total_rate_from;
            }
        });
        const filteredAvailableCurrencies = this.baseAvailableCurrencies.map(group => ({
            type: group.type,
            currencies: group.currencies.filter(currency => {
                if (group.type === 'fiat') {
                    return availableFiat.has(currency.name);
                } else if (group.type === 'crypto') {
                    return availableCrypto.has(currency.name.toUpperCase());
                }
                return false;
            })
        }));

        this.availableCurrencies = filteredAvailableCurrencies;
        this.exchangeRates = newExchangeRates;
        this.currencyPairs = normalizedPairs;
    }

    normalizeCurrencyCode(code) {
        if (!code) return code;
        const upperCode = code.toUpperCase();
        const replacements = { 'UDST': 'USDT' };
        return replacements[upperCode] || upperCode;
    }
    normalizeCurrencyPair(pair) {
        return {
            ...pair,
            code_one: this.normalizeCurrencyCode(pair.code_one),
            code_two: this.normalizeCurrencyCode(pair.code_two)
        };
    }

    switchCurrencies() {
        const tempCurrency = this.currencyTo;
        const tempAmount = this.amountTo;

        this.currencyTo = this.currencyFrom;
        this.currencyFrom = tempCurrency;
        this.amountTo = this.amountFrom;
        this.amountFrom = tempAmount;
    }

    updateAmountTo(value) {
        if (value && value !== undefined) {
            this.amountTo = value;
            const rate = this.getExchangeRateBetweenCurrencies(this.currencyFrom, this.currencyTo);
            console.log(rate)
            this.amountFrom = (parseFloat(value) / rate).toFixed(2);
        } else {
            this.amountFrom = '';
            this.amountTo = '';
        }
    }
    updateAmountFrom(value) {
        if (value) {
            this.amountFrom = value;
            const rate = this.getExchangeRateBetweenCurrencies(this.currencyFrom, this.currencyTo);
            console.log(rate)
            this.amountTo = (parseFloat(value) * rate).toFixed(2);
        } else {
            this.amountFrom = '';
            this.amountTo = '';
        }
    }

    getExchangeRateBetweenCurrencies(fromCurrency, toCurrency) {
        if (!fromCurrency || !toCurrency) return 1;
        const normalizedFromCode = this.normalizeCurrencyCode(fromCurrency.name.toUpperCase());
        const normalizedToCode = this.normalizeCurrencyCode(toCurrency.name.toUpperCase());

        const pair = this.currencyPairs.find(p =>
            (p.code_one === normalizedFromCode && p.code_two === normalizedToCode) ||
            (p.code_two === normalizedFromCode && p.code_one === normalizedToCode)
        );

        if (!pair) return 1;

        if (pair.code_one === normalizedFromCode) {
            return pair.total_rate_to;
        } else {
            return pair.total_rate_from;
        }
    }

    getCurrencyType(currency) {
        if (!currency) return null;

        for (const group of this.availableCurrencies) {
            if (group.currencies.some(c => c.name === currency.name)) {
                return group.type;
            }
        }
        return null;
    }
    setCurrencyTo(currency) {
        if (this.canSelectCurrency(currency, 'to')) {
            this.currencyTo = currency;
            if (this.amountFrom) {
                this.updateAmountTo(this.amountFrom);
            }
        }
    }
    setCurrencyFrom(currency) {
        if (this.canSelectCurrency(currency, 'from')) {
            this.currencyFrom = currency;
            if (this.amountTo) {
                this.updateAmountFrom(this.amountTo);
            }
        }
    }
    canSelectCurrency(currency, targetField) {
        if (!currency || !currency.name) {
            console.warn("Выбранная валюта не определена:", currency);
            return false;
        }
        // Получаем текущую валюту из противоположного поля
        const otherCurrency = targetField === 'to' ? this.currencyFrom : this.currencyTo;

        if (!otherCurrency || !otherCurrency.name) {
            console.warn("Противоположная валюта не определена:", otherCurrency);
            return false;
        }
        // Проверяем, не является ли выбираемая валюта той же самой
        if (currency.name === otherCurrency.name) {
            return false;
        }

        // Получаем типы валют
        const targetCurrencyType = this.getCurrencyType(currency);
        const otherCurrencyType = this.getCurrencyType(otherCurrency);

        // Проверяем, не являются ли типы валют одинаковыми
        return targetCurrencyType !== otherCurrencyType;
    }
    async createRequestExchange() {

        if (!this.currencyFrom || !this.currencyTo || /*!this.wallet ||*/ !this.amountFrom || !this.amountTo) {
            this.errorMessage = 'Все поля должны быть заполнены для создания заявки.';
            return;
        }

        try {
            const currentPair = this.currencyPairs.find(
                (p) =>
                    (p.code_one === this.currencyFrom.name.toUpperCase() && p.code_two === this.currencyTo.name.toUpperCase()) ||
                    (p.code_two === this.currencyFrom.name.toUpperCase() && p.code_one === this.currencyTo.name.toUpperCase())
            );

            if (!currentPair) {
                throw new Error('Пара валют не найдена');
            }
            const isDirectOrder = currentPair.code_one === this.currencyFrom.name.toUpperCase();
            const transaction_direction = isDirectOrder ? 'to' : 'from';
            const data = {
                currency_pair_id: currentPair.id,
                wallet: this.wallet,
                transaction_direction,
                amount_from: parseFloat(this.amountFrom),
                amount_to: parseFloat(this.amountTo),
            };

            const response = await $api.post('/tr/conv', data);

            if (response.status === 201) {
                this.requestSuccess = true;
                this.successMessage = 'Заявка на обмен успешно создана';
                this.SuccessRequestId = response.data.id;
                this.resetForm();

            }
        } catch (error) {
            console.error('Ошибка при создании заявки:', error);
            this.errorMessage = 'Ошибка при создании заявки. Попробуйте позже.';
        }
    }

    resetForm() {
        this.amountFrom = '';
        this.amountTo = '';
        this.wallet = '';
        this.currencyFrom = this.baseAvailableCurrencies[1]?.currencies?.[1] || null;
        this.currencyTo = this.baseAvailableCurrencies[0]?.currencies?.[0] || null;
        this.requestSuccess = false;
        this.successMessage = '';
        this.errorMessage = '';
        //this.SuccessRequestId = '';
    }
    set CurrencyTo(currency) {
        this.currencyTo = currency;
    }
    get CurrencyTo() {
        return toJS(this.currencyTo);
    }

    set CurrencyFrom(currency) {
        this.currencyFrom = currency;
    }
    get CurrencyFrom() {
        return toJS(this.currencyFrom);
    }

    set AmountTo(amount) {
        this.amountTo = amount;
    }
    get AmountTo() {
        return toJS(this.amountTo);
    }

    set AmountFrom(amount) {
        this.amountFrom = amount;
    }
    get AmountFrom() {
        return toJS(this.amountFrom);
    }
    set AvailableCurrencies(value) {
        this.availableCurrencies = value;
    }
    get AvailableCurrencies() {
        return toJS(this.availableCurrencies);
    }
    get Wallet() {
        return toJS(this.wallet);
    }
    setWallet = (value) => {
        this.wallet = value;
    }
    set RequestSuccess(value) {
        this.requestSuccess = value;
    }
    set SuccessRequestId(value) {
        this.successRequestId = value
    }
    get SuccessRequestId() {
        return toJS(this.successRequestId);
    }
}

export default new ExchangeStore();
