import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import MoneyInput from '../../components/MoneyInput/MoneyInput';
import WalletInput from '../../components/WalletInput/WalletInput';
import SelectDialog from '../../components/SelectDialog/SelectDialog';
import NotificationModal from '../../components/NotificationModal/NotificationModal';
import exchangeStore from '../../stores/ExchangeStore';
import webAppStore from '../../stores/WebAppStore';
import userStore from '../../stores/UserStore';
import { ReactComponent as InfoIcon } from '../../images/Info.svg';
import { ReactComponent as Dropdown } from '../../images/dropDown.svg';
import secured from '../../images/png/Secured.png';
import styles from './ExchangeScreen.module.scss';
import SuccessRequest from '../../components/SuccessRequest/SuccessRequest';
const ExchangeScreen = observer(() => {

  const [isAgreedToExchange, setIsAgreedToExchange] = useState(false);
  const [isAgreedToAMLKYC, setIsAgreedToAMLKYC] = useState(false);

  const [isValid, setIsValid] = useState(false);

  const navigate = useNavigate();
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await userStore.fetchUserData(); // Ждем завершения загрузки данных пользователя
        if (userStore.CurrencyPairs) {
          exchangeStore.fetchAvailableCurrencies(userStore.CurrencyPairs); // Передаем валюты в ExchangeStore
        }
      } catch (error) {
        console.error('Ошибка при загрузке данных:', error);
      }
    };
    fetchData();
    webAppStore.updateBackButtonVisibility();
    //return () => exchangeStore.resetForm();
  }, []);

  useEffect(() => {
    if (exchangeStore.requestSuccess) {
      setShowNotification(true);
      const timer = setTimeout(() => {
        setShowNotification(false);
        navigate('/');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [exchangeStore.requestSuccess]);

  const validateForm = () => {
    const isFormValid =
      userStore.Fio &&
      userStore.Phone &&
      exchangeStore.currencyFrom &&
      exchangeStore.currencyTo &&
      exchangeStore.amountFrom &&
      exchangeStore.amountTo &&
      isAgreedToExchange &&
      isAgreedToAMLKYC;
    setIsValid(isFormValid);
  };
  useEffect(() => {
    validateForm();
  }, [
    userStore.Fio,
    userStore.Phone,
    exchangeStore.currencyFrom,
    exchangeStore.currencyTo,
    exchangeStore.amountFrom,
    exchangeStore.amountTo,
    isAgreedToExchange,
    isAgreedToAMLKYC,
  ]);

  const getFilteredCurrencies = (isReceiving) => {
    if (!exchangeStore.availableCurrencies || exchangeStore.availableCurrencies.length === 0) {
      return [];
    }

    return exchangeStore.availableCurrencies
      .map(group => ({
        ...group,
        currencies: (group.currencies || []).filter(currency =>
          exchangeStore.canSelectCurrency(currency, isReceiving ? 'to' : 'from')
        ),
      }))
      .filter(group => group.currencies.length > 0);
  };

  const handleSwitch = () => {
    exchangeStore.switchCurrencies();
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    await exchangeStore.createRequestExchange();
    console.log(exchangeStore.SuccessRequestId)
  };
  const onInfoClick = (e) => {
    e.preventDefault();
    navigate('/changeInfo');
  }

  const isFormValid = exchangeStore.currencyFrom &&
    exchangeStore.currencyTo &&
    exchangeStore.amountFrom &&
    exchangeStore.amountTo &&
    isValid &&
    isAgreedToAMLKYC &&
    isAgreedToExchange;

  const showCountrySelector = () => {
    userStore.showCountryChangeBlock = !userStore.showCountryChangeBlock;
  };

  const showCitySelector = () => {
    if (userStore.country) {
      userStore.showCityChangeBlock = !userStore.showCityChangeBlock;
    }
  };

  const handleCountrySelect = (newCountry) => {
    const selectedCountry = userStore.countries.find(c => c.value === newCountry);
    userStore.Country = selectedCountry;
    userStore.City = null;

  };

  const handleCitySelect = async (newCity) => {
    const cityObject = userStore?.cities.find(c => c.value === newCity);
    userStore.City = cityObject;
    if (cityObject) {
      try {
        await userStore.updateUserData();
      } catch (error) {
        console.error('Ошибка при обновлении города:', error);
      }
    }
  };

  const getExchangeRateText = () => {
    const fromCurrency = exchangeStore.currencyFrom?.name.toUpperCase();
    const toCurrency = exchangeStore.currencyTo?.name.toUpperCase();
    const rate = exchangeStore.getExchangeRateBetweenCurrencies(exchangeStore.currencyFrom, exchangeStore.currencyTo);

    if (!rate) return 'Курсы валют недоступны';
    return `1 ${fromCurrency} = ${rate} ${toCurrency}`;
  };

  if (exchangeStore.SuccessRequestId) {
    // Отображение информации об успешной заявке
    return <SuccessRequest store={exchangeStore} />;
    /*return (
      <div className={styles.requestPage}>
        <div className={styles.imageBlock}>
          <img src={secured} alt="Shield" />
        </div>
        <h1 className={styles.successTitle}>Заявка создана</h1>
        <div className={`${styles.headerDescription} ${styles.selector} ${styles.successTitle}`}>
          Ваша заявка передана менеджеру
        </div>
        <div className={styles.request}>
          <div className={styles.requestBlock}>
            <div className={styles.requestBlockLabel}>Номер заявки</div>
            <div className={styles.requestBlockId}>{exchangeStore.successRequestId}</div>
          </div>
          <div className={styles.requestBtnPosition}>
            <Button type="btnThree" label="Связаться с менеджером" onClick={() => navigate('/')} />
          </div>
        </div>
      </div>
    );*/
  }

  return (
    <div className={styles.requestPage}>
      <div className={styles.headerRow}>
        <div className={styles.headerTitle}>
          Обмен
          электронных
          валют
        </div>
        <div className={styles.infoIconBlock} onClick={onInfoClick}>
          <InfoIcon />
        </div>
      </div>
      <div className={styles.headerDescription}>
        Оставьте заявку, мы отправим
        вам инструкцию для получения
      </div>
      {/*Country and city selector */}
      <div
        className={`${styles.selector}`}
        onClick={() => userStore.showCountryChangeBlock = true}
      >
        <input
          type="text"
          placeholder="Страна"
          value={userStore.Country?.label || ''}
          readOnly
          className={`${styles.input} ${styles.inputSelec}`}
        />
        <Dropdown className={styles.dropdownIcon} />
      </div>

      <div
        className={`${styles.selector} ${!userStore.Country ? styles.disabled : ''}`}
        onClick={() => userStore.showCityChangeBlock = true}
      >
        <input
          type="text"
          placeholder="Город"
          value={userStore.City?.label || ''}
          readOnly
          className={`${styles.input} ${styles.inputSelec}`}
        />
        <Dropdown className={styles.dropdownIcon} />
      </div>

      <div className={styles.exchangeRate}>
        {getExchangeRateText()}
      </div>
      {/*<div className={styles.layout}></div>*/}

      <div className={styles.exchangeBlock}>
        <div className={styles.recieveOrGiveBlock}>
          <div className={styles.labelDescription}>Вы получаете</div>
          <MoneyInput
            value={exchangeStore.AmountTo}
            onChange={(value) => exchangeStore.updateAmountTo(value)}
            currency={exchangeStore.CurrencyTo}
            onCurrencySelect={(currency) => exchangeStore.setCurrencyTo(currency)}
            availableCurrencies={getFilteredCurrencies(true)}
            isReceiving={true}
          />
        </div>
        <div className={styles.switch} onClick={handleSwitch}>
          <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24" fill="none">
            <path d="M11.9498 7.94975L10.5356 9.36396L8.00079 6.828L8.00004 20H6.00004L6.00079 6.828L3.46451 9.36396L2.05029 7.94975L7.00004 3L11.9498 7.94975ZM21.9498 16.0503L17 21L12.0503 16.0503L13.4645 14.636L16.0008 17.172L16 4H18L18.0008 17.172L20.5356 14.636L21.9498 16.0503Z" fill="#FF4D00" />
          </svg>
        </div>
        <div className={styles.recieveOrGiveBlock}>
          <div className={styles.labelDescription}>Вы отдаете</div>
          <MoneyInput
            value={exchangeStore.AmountFrom}
            onChange={(value) => exchangeStore.updateAmountFrom(value)}
            currency={exchangeStore.CurrencyFrom}
            onCurrencySelect={(currency) => exchangeStore.setCurrencyFrom(currency)}
            availableCurrencies={getFilteredCurrencies(false)}
            isReceiving={false}
          />
        </div>
      </div>
      {/*<div className={styles.walletBlock}>
        <WalletInput
          value={exchangeStore.Wallet}
          onChange={exchangeStore.setWallet}
          onValidationChange={setIsValid}
          inputType={exchangeStore.getCurrencyType(exchangeStore.CurrencyTo)}
        />
      </div>*/}
      <input
        type="text"
        placeholder="Ваше имя*"
        className={styles.input}
        value={userStore.Fio}
        onChange={(e) => userStore.setFio(e.target.value)}
      />
      <input
        type="text"
        placeholder="Ваш телефон*"
        className={styles.input}
        value={userStore.Phone}
        onChange={(e) => userStore.setPhone(e.target.value)}

      />
      <div className={styles.agreementBlock}>
        <label>
          <input
            type="checkbox"
            checked={isAgreedToExchange}
            onChange={(e) => setIsAgreedToExchange(e.target.checked)}
          />
          Я согласен с&nbsp;<a href="/changeInfo"> условиями обмена</a>
        </label>

      </div>
      <div className={styles.agreementBlock}>
        <label>
          <input
            type="checkbox"
            checked={isAgreedToAMLKYC}
            onChange={(e) => setIsAgreedToAMLKYC(e.target.checked)}
          />
          Я  согласен с&nbsp;<a href="/changeAmlInfo"> правилами AML/KYC</a>
        </label>
      </div>
      <div className={styles.buttonsBlock}>
        <Button type="btnThree" onClick={onSubmit} disabled={!isFormValid} label="Создать заявку" />

      </div>
      <NotificationModal
        isOpen={showNotification}
        onClose={() => setShowNotification(false)}
      >
        <p>{exchangeStore.SuccessMessage}</p>
      </NotificationModal>
      {userStore.showCountryChangeBlock && (
        <SelectDialog
          isOpen
          items={userStore.Countries}
          title="Выберите страну"
          onSelect={handleCountrySelect}
          onClose={() => userStore.showCountryChangeBlock = false}
        />
      )}

      {userStore.showCityChangeBlock && (
        <SelectDialog
          isOpen
          items={userStore.availableCities}
          title="Выберите город"
          onSelect={handleCitySelect}
          onClose={() => userStore.showCityChangeBlock = false}
        />
      )}
    </div>
  );
});

export default ExchangeScreen;
