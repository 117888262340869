import React from 'react';
import InfoScreen from '../../components/InfoScreen/InfoScreen';

const AmlInfoScreen = () => {
    return (
        <InfoScreen
            header="Правила AML и KYC"
            description={`1. Общие положения
Наш сервис соблюдает международные стандарты AML (Anti-Money Laundering, Противодействие Отмыванию Денег) и KYC (Know Your Customer, Знай Своего Клиента) для предотвращения незаконных финансовых операций, мошенничества и финансирования терроризма.

2. Политика KYC (Знай Своего Клиента)
Для проведения обмена сервис может запросить у клиента:

Паспорт или другое удостоверение личности (для граждан России – паспорт РФ, для иностранных граждан – загранпаспорт или другой документ, подтверждающий личность).
Подтверждение источника средств, если сумма обмена превышает установленный лимит.
Селфи с документом для верификации личности (по требованию).
Контактные данные (номер телефона, Telegram-аккаунт).
Отказ от предоставления данных может стать причиной отказа в обмене.

3. Политика AML (Противодействие Отмыванию Денег)
В рамках борьбы с незаконными операциями сервис имеет право:

Блокировать обменные операции, если они вызывают подозрение.
Требовать дополнительную верификацию личности и подтверждение происхождения средств.
Сообщать о подозрительных операциях в компетентные органы.
Отказывать в обслуживании клиентам, замеченным в мошеннических действиях, использовании средств, полученных преступным путем, или уклонении от AML-проверок.
4. Ограничения и запрещенные операции
Обмен запрещен для клиентов, которые:

Используют криптовалюту, полученную через незаконные источники (взломанные кошельки, даркнет, фишинговые схемы).
Пытаются обойти систему AML и KYC путем предоставления ложных данных.
Являются гражданами стран, попавших под международные санкции.
Замечены в финансировании терроризма, распространении наркотиков и другой преступной деятельности.
5. Политика конфиденциальности
Все данные клиентов хранятся в строгой конфиденциальности и используются только для целей KYC и AML проверки.`}
            backLink="/aml" />
    );
}

export default AmlInfoScreen;
