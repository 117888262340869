import { makeAutoObservable } from 'mobx';

class WebAppStore {
  isInWebApp = false;
  backButtonVisible = false;
  isMenuVisible = true;
  constructor() {
    makeAutoObservable(this);
    this.initialize();
  }

  initialize() {
    console.debug('Initializing WebAppStore...');
    if (window.Telegram.WebApp) {
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.expand();
      this.isInWebApp = true;
      console.debug('Running in Telegram miniApp');
      this.updateThemeParams();
      this.updateBackButtonVisibility();

      window.Telegram.WebApp?.BackButton.onClick(() => {
        this.handleBackButton();
      });
    }
  }
  setMenuVisibility(isVisible) {
    this.isMenuVisible = isVisible;
  }

  updateThemeParams() {
    const theme = window.Telegram.WebApp.themeParams;
    console.debug(theme)
    this.themeParams = {
      accentTextColor: theme.accent_text_color || '#e84444',
      backgroundColor: theme.bg_color || '#ffffff',
      textColor: theme.text_color || '#000000',
      buttonTextColor: theme.button_text_color || '#ffffff',
      destructiveTextColor: theme.destructive_text_color || '#f57474',
      headerBgColor: theme.header_bg_color || '#282e33',
      buttonColor: '#ff4d00',
      hintColor: theme.hint_color || '#82868a',
      linkColor: theme.link_color || '#e10311',
      secondaryBgColor: theme.secondary_bg_color || '#82868a',
      sectionBgColor: theme.section_bg_color || '#282e33',
      sectionHeaderTextColor: theme.section_header_text_color || '#e10311',
      sectionSeparatorColor: theme.section_separator_color || '#242a2e',
      subtitleTextColor: theme.subtitle_text_color || '#82868a',
    };
  }
  updateBackButtonVisibility() {
    if (window.location.pathname !== '/') {
      this.showBackButton();
    } else {
      this.hideBackButton();
    }
  }

  showBackButton() {
    this.backButtonVisible = true;
    window.Telegram.WebApp?.BackButton.show();
  }

  hideBackButton() {
    this.backButtonVisible = false;
    window.Telegram.WebApp?.BackButton.hide();
  }

  handleBackButton() {
    if (this.backButtonVisible) {
      window.history.back();
      this.updateBackButtonVisibility();
    }
  }

  handleSuccessRequest() {
    console.debug('Processing success request...');
    window.history.pushState(null, '', '/');
    this.hideBackButton();
    window.location.replace('/');
  }
}

export default new WebAppStore();
