import React, { useState, useCallback } from 'react';
import { CSSTransition } from 'react-transition-group';
import { ReactComponent as Dropdown } from '../../images/dropDown.svg';
import './MoneyInput.scss';
const MoneyInput = ({
  value,
  onChange,
  currency,
  onCurrencySelect,
  availableCurrencies,
  placeholder = '1',
  isReceiving
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isShaking, setIsShaking] = useState(false);


  const handleInputChange = useCallback((e) => {
    const newValue = e.target.value;
    if (newValue && newValue === '' || /^\d*\.?\d*$/.test(newValue.replace(',', '.'))) {
      onChange(newValue.replace(',', '.'));
    } else {
      setIsShaking(true);
      setTimeout(() => setIsShaking(false), 500);
    }
  }, [onChange]);
  const handleCurrencyClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleCurrencySelect = (newCurrency) => {
    onCurrencySelect(newCurrency);
    setIsDropdownOpen(false);
  };

  return (
    <div
      className={`inputContainer ${isShaking ? 'shake' : ''}`}
    >
      <div className="wrapper">
        {currency && (
          <div className="currencyLabel" onClick={handleCurrencyClick}>
            <div className='currencyIcon'>
              <span>{currency.icon}</span>
            </div>
            <span>{currency.name.toUpperCase()}</span>
            <Dropdown style={{ marginLeft: '10px', paddingRight: '20px' }} />
          </div>
        )}
        <div className="field">
          <input
            className="inputField"
            type="text"
            inputMode="decimal"
            pattern="[0-9]*"
            value={value}
            onChange={handleInputChange}
            autoComplete="off"
          />
          {!value && (
            <div
              className="placeholder"
            >
              {placeholder}
            </div>
          )}
        </div>

      </div>
      <CSSTransition
        in={isDropdownOpen}
        timeout={1000}
        classNames="openBonusFon"
        unmountOnExit
      >
        <div className="modal-overlay" onClick={() => setIsDropdownOpen(false)} />
      </CSSTransition>

      <CSSTransition
        in={isDropdownOpen}
        timeout={500}
        classNames="openBonusMenu"
        unmountOnExit
      >
        <div className="currency-dropdown" onClick={e => e.stopPropagation()}>
          <div className="close-button" onClick={() => setIsDropdownOpen(false)}>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L13 13M1 13L13 1" stroke="white" strokeWidth="2" strokeLinecap="round" />
            </svg>
          </div>
          <div className="modal-title">Выберите валюту</div>
          {availableCurrencies && availableCurrencies.map((group) => (
            <div key={group.type} className="currency-group">
              <div className="group-title">{group.type.toUpperCase()}</div>
              {group && group.currencies && group.currencies.map((curr) => (
                <div
                  key={curr.name}
                  className={`currency-option ${currency.name === curr.name ? 'selected' : ''}`}
                  onClick={() => handleCurrencySelect(curr)}
                >
                  <div className="currency-content">
                    <span className="currency-icon">{curr.icon}</span>
                    <span className="currency-name">{curr.name.toUpperCase()}</span>
                    <div className="radio-indicator" />
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </CSSTransition>
    </div>
  );
};
export default MoneyInput;