import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import webAppStore from '../../../stores/WebAppStore';
import { ReactComponent as InfoIcon } from '../../../images/Info.svg';
import { ReactComponent as ArrowRight } from '../../../images/ArrowRight.svg';
import requestStore from '../../../stores/OldRequestStore';
import styles from './InvoiceScreen.module.scss';

const InvoiceScreen = observer(() => {
  const navigate = useNavigate();
  const [showNotification, setShowNotification] = useState(false);

  const [amount, setAmount] = useState('');
  const [comment, setComment] = useState('');

  useEffect(() => {
    if (requestStore.RequestSuccess) {
      setShowNotification(true);
      const timer = setTimeout(() => {
        setShowNotification(false);
        requestStore.resetSuccessState();
        setAmount('');
        setComment('');
        navigate('/');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [requestStore.RequestSuccess]);

  const handleSubmit = (e) => {
    e.preventDefault();
    requestStore.InvoiceRequest = { amount: amount, comment: comment };
    requestStore.createRequestPayment("invoice");
  };

  const handleFileAttach = (files) => {
    requestStore.setInvoiceRequestFiles(files);
  };
  useEffect(() => {
    webAppStore.updateBackButtonVisibility();
  }, []);
  const onInfoClick = (e) => {
    e.preventDefault();
    navigate('/invoiceInfo');
  }

  return (
    <div className={styles.invoicePayment}>
      {/*<div className={styles.layout}></div>*/}

      <div className={styles.invoicePaymentHeader}>
        <div className={styles.invoicePaymentTitle}>
          <span>
            Оплатить<br />
            инвойс
          </span>
        </div>
        <div className={styles.infoIconBlock} onClick={onInfoClick}>
          <InfoIcon />
        </div>
      </div>
      <div className={styles.headerDescription}>
        Оставьте заявку, мы отправим
        вам инструкцию для получения
      </div>
      <div className={styles.invoicePaymentBigButtons}>
        <div className={styles.invoicePaymentBigButton} onClick={() => navigate('/invoiceUsdt')}>
          <div className={styles.labelBigBtn}>USDT</div>
          <ArrowRight />
        </div>
        <div className={styles.invoicePaymentBigButton} onClick={() => navigate('/invoiceCash')}>
          <div className={styles.labelBigBtn}>Наличные</div>
          <ArrowRight />
        </div>
      </div>
      <div className={styles.headerDescription}>
        Принимаем платежи от $10к
      </div>
      <div className={styles.feePaymentBigButton} onClick={() => navigate('/invoiceFeeInfo')}>
        <div className={styles.labelBigBtn}>Комиссии</div>
        <ArrowRight />
      </div>
      {/*<div className={styles.invoicePaymentInputGroupComment}>
        <TextInput
          value={comment}
          onChange={setComment}
          placeholder="Комментарий"
          label="Комментарий"
          multiline={true}
          rows={4}
          onFileAttach={handleFileAttach}
        />
      </div>
      <NotificationModal
        isOpen={showNotification}
        onClose={() => setShowNotification(false)}
      >
        <p>{requestStore.SuccessMessage}</p>
      </NotificationModal>*/}
    </div>
  );
});

export default InvoiceScreen;
