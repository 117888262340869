import { makeAutoObservable, toJS } from 'mobx';
import $api from '../http';

class VedStore {
    data = {
        amount: '',
        beneficiaryName: '',
        beneficiaryAddress: '',
        bank: '',
        bankAddress: '',
        account: '',
        swift: '',
        currency: '',
        remark: '',
    };
    attachedFiles = [];
    requestSuccess = false;
    successMessage = '';
    successRequestId = null;
    errorMessage = ''
    constructor() {
        makeAutoObservable(this);
    }

    setField(field, value) {
        this.data[field] = value;
    }
    setVedRequestFiles(files) {
        this.attachedFiles = files;
    }

    removeVedRequestFile(index) {
        this.attachedFiles = this.attachedFiles.filter((_, i) => i !== index);
    }
    async submitVed() {
        try {
            const formData = new FormData();

            // Добавляем текстовые данные в FormData
            Object.keys(this.data).forEach((key) => {
                formData.append(key, this.data[key]);
            });

            // Добавляем файлы, если они есть
            this.attachedFiles.forEach((file, index) => {
                formData.append(`files[${index}]`, file);
            });

            const response = await $api.post('/tr/wed', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.status === 201) {
                this.RequestSuccess = true;
                this.successMessage = 'Заявка на обмен успешно создана';
                this.SuccessRequestId = response.data.id;
                console.log(this.requestSuccess);
                this.resetForm();

            }
            if (!response.ok) {
                throw new Error('Ошибка при отправке запроса');
            }
            console.log('Invoice submitted successfully');
        } catch (error) {
            console.error('Ошибка:', error);
        }
    }

    isFormValid() {
        return true;
        /*if (this.data["amount"] &&
            this.data["beneficiaryName"] &&
            this.data["beneficiaryAddress"] &&
            this.data["bank"] &&
            this.data["bankAddress"] &&
            this.data["account"] &&
            this.data["swift"] &&
            this.data["currency"] &&
            this.data["remark"]
        ) {
            return true;
        }
        else {
            return false;
        }*/
    }
    set RequestSuccess(value) {
        this.requestSuccess = value;
    }
    set SuccessRequestId(value) {
        this.successRequestId = value
    }
    get SuccessRequestId() {
        return toJS(this.successRequestId);
    }
    resetForm() {
        this.data = {
            amount: '',
            beneficiaryName: '',
            beneficiaryAddress: '',
            bank: '',
            bankAddress: '',
            account: '',
            swift: '',
            currency: '',
            remark: ''
        };
        this.requestSuccess = false;
        this.successMessage = '';
        this.errorMessage = '';
        this.attachedFiles = []
        //this.SuccessRequestId = '';
    }
}

export default new VedStore();
