import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import SelectDialog from '../../../components/SelectDialog/SelectDialog';
import SuccessRequest from '../../../components/SuccessRequest/SuccessRequest';
import webAppStore from '../../../stores/WebAppStore';
import { ReactComponent as InfoIcon } from '../../../images/Info.svg';
import styles from './InvoiceCashScreen.module.scss';
import InvoiceStore from '../../../stores/InvoiceStore';
import userStore from '../../../stores/UserStore';
import InvoicePaymentDataBlock from '../../../components/InvoicePaymentDataBlock/InvoicePaymentDataBlock';

const InvoiceCashScreen = observer(() => {
    const navigate = useNavigate();

    useEffect(() => {
        webAppStore.updateBackButtonVisibility();
    }, []);
    useEffect(() => {
        const initialize = async () => {
            try {
                await userStore.fetchUserData();
            } catch (error) {
                console.error('Ошибка загрузки данных пользователя:', error);
            }
        };
        initialize();
    }, []);

    const onInfoClick = (e) => {
        e.preventDefault();
        navigate('/invoiceInfo');
    }
    const handleCountrySelect = (newCountry) => {
        const selectedCountry = userStore.countries.find(c => c.value === newCountry);
        userStore.Country = selectedCountry;
        userStore.City = null;

    };

    const handleCitySelect = (newCity) => {
        const cityObject = userStore?.cities.find(c => c.value === newCity);
        userStore.City = cityObject;
    };

    const fields = [
        { name: 'amount', placeholder: 'Сумма' },
        { name: 'beneficiaryName', placeholder: 'Beneficiary name' },
        { name: 'beneficiaryAddress', placeholder: 'Beneficiary address' },
        { name: 'bank', placeholder: 'Bank' },
        { name: 'bankAddress', placeholder: 'Bank address' },
        { name: 'account', placeholder: 'Account' },
        { name: 'swift', placeholder: 'SWIFT' },
        { name: 'currency', placeholder: 'Currency' },
        { name: 'remark', placeholder: 'Remark' }
    ];
    const onSubmit = async (e) => {
        e.preventDefault();
        await InvoiceStore.submitCashInvoice(userStore.Country, userStore.City);
        console.log(InvoiceStore.SuccessRequestId)
    };
    if (InvoiceStore.SuccessRequestId) {

        return <SuccessRequest store={InvoiceStore} />;
    }
    return (
        <div className={styles.invoicePayment}>
            {/*<div className={styles.layout}></div>*/}

            <div className={styles.invoicePaymentHeader}>
                <div className={styles.invoicePaymentTitle}>
                    <span>
                        Оплатить<br />
                        инвойс
                        за наличные
                    </span>
                </div>
                <div className={styles.infoIconBlock} onClick={onInfoClick}>
                    <InfoIcon />
                </div>
            </div>

            <div className={styles.invoicePaymentDataBlocks}>
                <InvoicePaymentDataBlock fields={fields} style={styles.invoicePaymentDataBlock} showCountryCity={true} />
            </div>
            <div className={styles.buttonsBlock}>
                <Button type="btnThree" onClick={onSubmit} disabled={!InvoiceStore.isFormValid} label="Создать заявку" />
            </div>
            {/*<div className={styles.invoicePaymentInputGroupComment}>
        <TextInput
          value={comment}
          onChange={setComment}
          placeholder="Комментарий"
          label="Комментарий"
          multiline={true}
          rows={4}
          onFileAttach={handleFileAttach}
        />
      </div>
      <NotificationModal
        isOpen={showNotification}
        onClose={() => setShowNotification(false)}
      >
        <p>{requestStore.SuccessMessage}</p>
      </NotificationModal>*/}
            {userStore.showCountryChangeBlock && (
                <SelectDialog
                    isOpen
                    items={userStore.Countries}
                    title="Выберите страну"
                    onSelect={handleCountrySelect}
                    onClose={() => userStore.showCountryChangeBlock = false}
                />
            )}

            {userStore.showCityChangeBlock && (
                <SelectDialog
                    isOpen
                    items={userStore.availableCities}
                    title="Выберите город"
                    onSelect={handleCitySelect}
                    onClose={() => userStore.showCityChangeBlock = false}
                />
            )}
        </div>
    );
});

export default InvoiceCashScreen;
