import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import InfoScreen from '../../components/InfoScreen/InfoScreen';
import webAppStore from '../../stores/WebAppStore';
import styles from './VedScreen.module.scss';
import VedPaymentDataBlock from '../../components/VedPaymentDataBlock/VedPaymentDataBlock';
import VedStore from '../../stores/VedStore';
import SuccessRequest from '../../components/SuccessRequest/SuccessRequest';

const InnerVed = () => {
  const fields = [
    { name: 'amount', placeholder: 'Сумма' },
    { name: 'beneficiaryName', placeholder: 'Beneficiary name' },
    { name: 'beneficiaryAddress', placeholder: 'Beneficiary address' },
    { name: 'bank', placeholder: 'Bank' },
    { name: 'bankAddress', placeholder: 'Bank address' },
    { name: 'account', placeholder: 'Account' },
    { name: 'swift', placeholder: 'SWIFT' },
    { name: 'currency', placeholder: 'Currency' },
    { name: 'remark', placeholder: 'Remark' }
  ];
  return (
    <div className={styles.content}>
      <VedPaymentDataBlock fields={fields} style={styles.fieldsBlock} />
    </div>
  )
}

const VedScreen = observer(() => {
  const navigate = useNavigate();

  useEffect(() => {
    webAppStore.updateBackButtonVisibility();
  }, []);

  const InfoClick = () => {
    navigate('/vedInfo');
  }
  const onSubmit = async () => {
    await VedStore.submitVed();
  }

  if (VedStore.SuccessRequestId) {
    return (
      <SuccessRequest store={VedStore} />
    );
  }
  return (
    <InfoScreen
      header="Платежный 
                    агент"
      showInfo={true}
      onInfoClick={InfoClick}
      description={<InnerVed />}
      submitAsync={onSubmit}
      buttonLabel="Создать заявку"
      isFormValid={VedStore.isFormValid()} />
  );
});

export default VedScreen;

