import React from 'react';
import { observer } from 'mobx-react-lite';
import { ReactComponent as Dropdown } from '../../images/dropDown.svg';
import InvoiceUsdtStore from '../../stores/InvoiceStore';
import userStore from '../../stores/UserStore';
import styles from './InvoicePaymentDataBlock.module.scss'
import TextFieldGenerator from '../TextFieldsGenerator/TextFieldsGenerator';


const InvoicePaymentDataBlock = observer(({ fields, style, showCountryCity = false }) => {
    return (
        <div className={style}>
            {showCountryCity && (

                <div
                    className={`${styles.selector}`}
                    onClick={() => userStore.showCountryChangeBlock = true}
                >
                    <input
                        type="text"
                        placeholder="Страна"
                        value={userStore.Country?.label || ''}
                        readOnly
                        className={`${styles.input} ${styles.inputSelec}`}
                    />
                    <Dropdown className={styles.dropdownIcon} />
                </div>
            )}
            {showCountryCity && (<div
                className={`${styles.selector} ${!userStore.Country ? styles.disabled : ''}`}
                onClick={() => userStore.showCityChangeBlock = true}
            >
                <input
                    type="text"
                    placeholder="Город"
                    value={userStore.City?.label || ''}
                    readOnly
                    className={`${styles.input} ${styles.inputSelec}`}
                />
                <Dropdown className={styles.dropdownIcon} />
            </div>)}

            {/*fields.map((field) => (
                <TextInput
                    key={field.name}
                    value={InvoiceUsdtStore.invoiceData[field.name]}
                    onChange={(value) => InvoiceUsdtStore.setField(field.name, value)}
                    placeholder={field.placeholder}
                />
            ))*/}

            <TextFieldGenerator fields={fields} style={style} store={InvoiceUsdtStore} />
        </div>
    );
});

export default InvoicePaymentDataBlock;