import { makeAutoObservable, toJS } from 'mobx';
import $api from '../http';
class InvoiceStore {
    data = {
        amount: '',
        beneficiaryName: '',
        beneficiaryAddress: '',
        bank: '',
        bankAddress: '',
        account: '',
        swift: '',
        currency: '',
        remark: ''
    };
    attachedFiles = [];
    requestSuccess = false;
    successMessage = '';
    successRequestId = null;
    errorMessage = ''

    constructor() {
        makeAutoObservable(this);
    }

    setField(field, value) {
        this.data[field] = value;
    }
    setVedRequestFiles(files) {
        this.attachedFiles = files;
    }

    removeVedRequestFile(index) {
        this.attachedFiles = this.attachedFiles.filter((_, i) => i !== index);
    }
    async submitUsdtInvoice() {
        try {
            const response = await $api.post('/tr/invoice', this.data);
            if (response.status === 201) {
                this.RequestSuccess = true;
                this.successMessage = 'Заявка на обмен успешно создана';
                this.SuccessRequestId = response.data.id;
                console.log(this.requestSuccess);
                this.resetForm();

            }
            if (!response.ok) {
                throw new Error('Ошибка при отправке запроса');
            }
            console.log('Invoice submitted successfully');
        } catch (error) {
            console.error('Ошибка:', error);
        }
    }
    async submitCashInvoice(country, city) {
        try {
            const invoiceDataWithLocation = {
                ...this.data,
                country,
                city
            };
            const response = await $api.post('/tr/invoice', invoiceDataWithLocation);
            if (response.status === 201) {
                this.RequestSuccess = true;
                this.successMessage = 'Заявка на обмен успешно создана';
                this.SuccessRequestId = response.data.id;
                console.log(this.requestSuccess);
                this.resetForm();

            }
            if (!response.ok) {
                throw new Error('Ошибка при отправке запроса');
            }
            console.log('Invoice submitted successfully');
        } catch (error) {
            console.error('Ошибка:', error);
        }
    }
    isFormValid() {
        return true;
        /*if (this.data["amount"] &&
            this.data["beneficiaryName"] &&
            this.data["beneficiaryAddress"] &&
            this.data["bank"] &&
            this.data["bankAddress"] &&
            this.data["account"] &&
            this.data["swift"] &&
            this.data["currency"] &&
            this.data["remark"]
        ) {
            return true;
        }
        else {
            return false;
        }*/
    }
    set RequestSuccess(value) {
        this.requestSuccess = value;
    }
    set SuccessRequestId(value) {
        this.successRequestId = value
    }
    get SuccessRequestId() {
        return toJS(this.successRequestId);
    }
    resetForm() {
        this.data = {
            amount: '',
            beneficiaryName: '',
            beneficiaryAddress: '',
            bank: '',
            bankAddress: '',
            account: '',
            swift: '',
            currency: '',
            remark: ''
        };
        this.requestSuccess = false;
        this.successMessage = '';
        this.errorMessage = '';
        this.attachedFiles = []
        //this.SuccessRequestId = '';
    }
}

export default new InvoiceStore();
