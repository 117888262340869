import React from 'react';
import InfoScreen from '../../components/InfoScreen/InfoScreen';
import styles from './VedAboutScreen.module.scss';
import { ReactComponent as InfoIcon } from '../../images/Info.svg';
import { ReactComponent as AdvantagesIcon } from '../../images/advantageOk.svg';
import { Navigate, useNavigate } from 'react-router-dom';

const InnerVedAbout = () => {
    const advantages = [
        '3 способа денежных переводов',
        'Поступление от 1 до 3 дней',
        'Комиссия от 2%'
    ];
    const QAdata = [
        {
            question: 'Для кого мы работаем:',
            answers: [`Селлеры маркетплейсов`,
                `Владельцы торгового бизнеса Производства`,
                `Компании- импортеры`,
            ]
        },
        {
            question: 'Мы предлагаем:',
            answers: [`1) Белая кприптовалюта`,
                `2) Оплата через ВТБ Шанхай`,
                `3) Для более сложных, санкционных
                кодов используем платежного
                агента Гонконг, Оман, ОАЭ`,
            ]
        }];
    const QUnderlinedAnswers = [
        {
            question: 'Мы обеспечиваем:',
            answers: [
                `Широкую сеть собственных
                торговых домов`,
                `Надёжность: на рынке
                международных услуг с 2008 года`,
                `Компетентность: провели более
                1000 собственных и клиентских
                платежей`,
                `Финансовую логистику под ваши
                товары`,
                `Вариативность решений в
                зависимости от задач - Сервис и
                платежные решения для всех групп`,
            ],
        }
    ];
    return (<div className={styles.content}>
        <div className={styles.advantagesBlock}>
            {advantages?.map((value) => (
                <div
                    key={value}
                    className={styles.advantageItem}>
                    <AdvantagesIcon />
                    <div className={styles.advantageItemText}>
                        {value}
                    </div>
                </div>
            ))}
        </div>
        <div className={styles.qaBlock}>
            {QAdata?.map((item) => (
                <div
                    key={item.question}
                    className={styles.qaItem}>
                    <div className={styles.questionText}>
                        {item.question}
                    </div>
                    {item.answers.map((answer) => (
                        <div className={styles.answerText}>
                            {answer}
                        </div>
                    ))}
                </div>
            ))}
        </div>
        <div className={styles.qUndelinedBlock}>
            {QUnderlinedAnswers?.map((item) => (
                <div
                    key={item.question}
                    className={styles.qUnderlinedItem}>
                    <div className={styles.questionUnderlinedText}>
                        {item.question}
                    </div>
                    {item?.answers?.map((item) => (
                        <div className={styles.answerUnderlinedText}>
                            {item}
                        </div>
                    ))}

                </div>
            ))}
        </div>
    </div>);
}

const VedAboutScreen = () => {
    const navigate = useNavigate();

    const InfoClick = () => {
        navigate('/vedInfo');
    }

    return (
        <InfoScreen
            header="Платежный 
                    агент"
            showInfo={true}
            onInfoClick={InfoClick}
            description={<InnerVedAbout />}
            backLink="/ved"
            buttonLabel="Создать заявку" />
    );
}

export default VedAboutScreen;