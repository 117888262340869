import { makeAutoObservable, toJS } from 'mobx';
import axios from 'axios';

class AmlStore {
  quota = 0;
  result = null;
  isLoading = false;
  showRisksModal = false;
  wallet = '';
  isValid = false;
  constructor() {
    makeAutoObservable(this);
  }
  get Wallet() {
    return toJS(this.wallet);
  }
  setWallet = (value) => {
    this.wallet = value;
  }
  setIsValid(value) {
    this.isValid = value;
  }
  get IsValid() {
    return this.isValid;
  }
  get Quota() {
    return toJS(this.quota);
  }

  get Result() {
    return toJS(this.result);
  }

  get IsLoading() {
    return toJS(this.isLoading);
  }

  get ShowRisksModal() {
    return toJS(this.showRisksModal);
  }

  set ShowRisksModal(value) {
    this.showRisksModal = value;
  }

  async fetchQuota() {
    try {
      this.isLoading = true;
      const response = await axios.get('/aml/quota');
      this.quota = response.data.data.count;
    } catch (error) {
      console.error('Ошибка при получении квоты AML:', error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async checkWallet(wallet) {
    try {
      this.isLoading = true;
      const response = await axios.post('/aml/check', null, {
        params: { trc20_wallet: wallet }
      });
      this.result = response.data.data;
      await this.fetchQuota(); // Обновляем квоту после проверки
      return response.data.data;
    } catch (error) {
      console.error('Ошибка при проверке кошелька:', error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  reset() {
    this.result = null;
    this.showRisksModal = false;
  }
}

export default new AmlStore();
