import React, { useState, useCallback } from 'react';
import { Paperclip, XCircle, FileText, FileImage, ArchiveX } from 'lucide-react';
import styles from './TextInput.module.scss';

const TextInput = ({
  value,
  onChange,
  placeholder = "",
  label,
  multiline = false,
  rows = 3,
  onFileAttach
}) => {
  const [isShaking, setIsShaking] = useState(false);

  const [attachedFiles, setAttachedFiles] = useState([]);

  const handleInputChange = useCallback((e) => {
    const newValue = e.target.value;
    onChange(newValue);
  }, [onChange]);

  const handleFileAttach = (e) => {
    const file = e.target.files[0];
    const updatedFiles = [...attachedFiles, file];

    setAttachedFiles(updatedFiles);

    if (onFileAttach) {
      onFileAttach(updatedFiles);
    }
  };

  const handleFileDelete = (index) => {
    const updatedFiles = [...attachedFiles];
    updatedFiles.splice(index, 1);

    setAttachedFiles(updatedFiles);

    if (onFileAttach) {
      onFileAttach(updatedFiles);
    }
  };

  const getFileIcon = (file) => {
    const fileExtension = file.name.split('.').pop().toLowerCase();
    switch (fileExtension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return <FileImage className={styles.fileIcon} />;
      case 'zip':
      case 'rar':
      case 'tar':
      case 'gz':
        return <ArchiveX className={styles.fileIcon} />;
      default:
        return <FileText className={styles.fileIcon} />;
    }
  };

  const InputComponent = multiline ? 'textarea' : 'input';

  return (
    <div className={`${styles.textInputContainer} ${isShaking ? styles.shake : ''}`}>
      <div className={styles.textInputWrapper}>
        <div className={styles.textInputFieldWrapper}>
          <InputComponent
            className={`${styles.textInputField} ${multiline ? styles.multiline : ''}`}
            style={{ minHeight: multiline ? `${rows * 1.5}em` : 'auto' }}
            value={value}
            onChange={handleInputChange}
            placeholder={placeholder}
            rows={multiline ? rows : undefined}
            autoComplete="off"
          />
          {!value && (
            <div className={`${styles.textInputPlaceholder} ${multiline ? styles.multiline : ''}`}>
              {placeholder}
            </div>
          )}
        </div>
        {label && (
          <div className={styles.textInputLabelWrapper}>
            <div className={styles.textInputLabel}>{label}</div>
            <label htmlFor={`file-input-${label}`} className={styles.textInputFileAttach}>
              <Paperclip size={18} />
              <input
                id={`file-input-${label}`}
                type="file"
                onChange={handleFileAttach}
                className="sr-only"
              />
            </label>
          </div>
        )}
      </div>
      {attachedFiles.length > 0 && (
        <div className={styles.textInputAttachedFiles}>
          {attachedFiles.map((file, index) => (
            <div key={index} className={styles.textInputAttachedFile}>
              {getFileIcon(file)}
              <div className={styles.fileName}>{file.name}</div>
              <div className={styles.fileDelete} onClick={() => handleFileDelete(index)}>
                <XCircle size={16} />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TextInput;